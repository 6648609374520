<script setup></script>

<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/style/mixin';

.container {
  max-width: 320px;
  margin: 0 auto;
  padding: 0 24px;
    
  @include media-md {
    max-width: 768px;
    padding: 0 40px;
  }

  @include media-lg {
    max-width: 1024px;
  }

  @include media-xl {
    max-width: 1440px;
    padding: 0 140px;
  }

  @include media-xxl {
    max-width: 1920px;
    padding: 0 380px;
  }
}
</style>
