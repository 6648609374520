<script setup>
import { computed } from 'vue';

const props = defineProps({
    buttonType: {
        type: String,
        default: 'button'
    },
    buttonTheme: {
        type: String,
        default: 'pink'
    },
    buttonSize: {
        type: String,
        default: 'medium'
    },
    isDisabled: {
        type: Boolean,
        default: false
    }
})

const setTheme = computed(() => {
    return 'button_theme_' + props.buttonTheme
})

const setSize = computed(() => {
    return 'button_size_' + props.buttonSize
})
</script>

<template>
    <button :type="props.buttonType" class="button" :class="[setTheme, setSize]" :disabled="isDisabled">
        <slot></slot>
    </button>
</template>

<style scoped lang="scss">
@import "~/src/style/mixin.scss";

.button {
    border: none;
    border-radius: 16px;
    cursor: pointer;

    @include transition(.3s, background-color, color);

    &_theme_pink {
        background-color: var(--action-color);
        color: #000;

        &:hover {
            color: var(--title-color);
        }

        &:focus {
            background-color: #FF1368;
            color: var(--title-color);
        }

        &:disabled {
            opacity: .4;
            color: #000;
        }
    }

    &_size_medium {
        padding: 14px 60px;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
    }
}
</style>