<script setup>
import { computed } from "vue";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  tag: {
    type: String,
    default: "ol",
    validator(value) {
      return ["ol", "ul"].includes(value);
    },
  },
  itemSize: {
    type: String,
    default: "small",
  },
  color: {
    type: String,
    default: "white",
  },
});

const setColorOfItem = computed(() => {
  switch (props.color) {
    case "white-opacity":
      return "list__item_color_white-opacity";
    case "white":
      return "list__item_color_white";
    default:
      return "";
  }
});

const setSizeOfItem = computed(() => {
  switch (props.itemSize) {
    case "small":
      return "list__item_size_small";
    case "medium":
      return "list__item_size_medium";
    default:
      return "";
  }
});
</script>

<template>
  <component :is="tag" class="list">
    <li
      class="list__item"
      :class="[setSizeOfItem, setColorOfItem]"
      v-for="item in list"
      :key="item"
    >
      {{ item }}
    </li>
  </component>
</template>

<style lang="scss" scoped>
@import 'src/style/mixin';

.list {
  display: flex;
  flex-direction: column;
  padding-inline-start: 21px;

  @include media-md {
    padding-inline-start: 0;
  }

  ol &__item {
    padding-left: 14px;

    @include media-lg {
      max-width: 360px;
    }
  }

  &__item {
    &_size_small {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.4;
    }

    &_size_medium {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.2;
    }

    &_color_white-opacity {
      color: var(--title-color);
      opacity: 0.8;
    }

    &_color_white {
      color: var(--title-color);
    }

    &::marker {
      color: var(--list-marker-color);
    }
  }
}
</style>
