<script setup>
import AppContainer from "@/components/common/AppContainer.vue";
import AppTitle from "@/components/common/AppTitle.vue";
import AppVacanciesDesktop from "@/components/AppVacanciesDesktop.vue";
import { computed, onMounted, ref } from "vue";
import AppVacanciesMobile from "@/components/AppVacanciesMobile.vue";

const isMobile = ref(false);

const matchMedia = window.matchMedia("(min-width: 768px)");

const setIsMobile = () => {
  matchMedia.matches ? (isMobile.value = true) : (isMobile.value = false);
};

matchMedia.addEventListener("change", setIsMobile);

onMounted(setIsMobile);

const setCurrentComponent = computed(() => {
  return isMobile.value ? AppVacanciesDesktop : AppVacanciesMobile;
});
</script>

<template>
  <section class="section-open-positions" id="positions">
    <AppContainer class="section-open-positions__container">
      <AppTitle class="section-open-positions__title" size="big">
        Open positions
      </AppTitle>

      <component :is="setCurrentComponent" />
    </AppContainer>
  </section>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.section-open-positions {
  &__container {
    padding-bottom: 80px;

    @include media-md {
      padding-bottom: 120px;
    }

    @include media-xl {
      padding-bottom: 140px;
    }
  }

  &__title {
    margin: 0 0 40px;
  }

  &__vacancies {
    margin-bottom: 16px;
  }
}
</style>
