<script setup>
import AppNavLink from "@/components/common/AppNavLink.vue";
import { onMounted, ref } from "vue";

const menuItems = ref([
  {
    name: "About us",
    href: "#about-us",
    isActive: false,
  },
  {
    name: "Our expertise",
    href: "#our-expertise",
    isActive: false,
  },
  {
    name: "Career",
    href: "#career",
    isActive: false,
  },
  {
    name: "Open positions",
    href: "#positions",
    isActive: false,
  },
  {
    name: "Contacts",
    href: "#contacts",
    isActive: false,
  },
]);

const activeMenu = ref("");

const clearActiveMenu = () => {
  menuItems.value.forEach((item) => {
    item.isActive = false;
  });
};

const setActiveMenu = () => {
  clearActiveMenu();

  menuItems.value.find((menuItem) => {
    if (activeMenu.value === menuItem.href.slice(1)) {
      menuItem.isActive = true;
    }
  });
};

const findSections = menuItems.value.map((item) => {
  return item.href;
});

onMounted(() => {
  const observedSections = findSections.map((section) => {
    return document.querySelector(section);
  });

  const callbackIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeMenu.value = entry.target.id;
        setActiveMenu();
      } else if (activeMenu.value === entry.target.id) {
        clearActiveMenu();
      }
    });
  };

  const optionsIntersection = {
    threshold: 0.65,
  };

  const observer = new IntersectionObserver(
    callbackIntersection,
    optionsIntersection
  );

  observedSections.forEach((section) => observer.observe(section));
});
</script>

<template>
  <nav class="nav">
    <ul class="nav__list">
      <li class="nav__item" v-for="item in menuItems" :key="item.name">
        <AppNavLink :href="item.href" :is-active="item.isActive">
          {{ item.name }}
        </AppNavLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.nav {
  @include media-lg {
    position: sticky;
    max-width: 141px;
    top: 235px;
    transform: translateX(9px);
    pointer-events: all;
  }

  &__list {
    @include media-lg {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__item {
    @include media-lg {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
