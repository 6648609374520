<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  inputType: {
    type: String,
    default: "text",
  },
  inputPlaceholder: {
    type: String,
    default: "",
  },
  inputSize: {
    type: String,
    default: "medium",
  },
  inputTheme: {
    type: String,
    default: "dark",
  },
  isRelative: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  nameProps: {
    type: String,
    required: true,
  },
});

const setSize = computed(() => {
  return "input_size_" + props.inputSize;
});

const setTheme = computed(() => {
  return "input_theme_" + props.inputTheme;
});

const setPadding = computed(() => {
  return { paddingRight: props.isRelative ? "200px" : "0" };
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (inputEvent) => {
  emit("update:modelValue", inputEvent.target.value);
};
</script>

<template>
  <label class="label">
    <input
      :type="inputType"
      :name="nameProps"
      :value="modelValue"
      @input="onInput"
      :placeholder="inputPlaceholder"
      class="input"
      :class="[setTheme, setSize]"
      :required="isRequired"
      :style="setPadding"
    />
  </label>
</template>

<style scoped lang="scss">
@import "src/style/mixin";

.label {
  display: block;
}

.input {
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: var(--paragraph-color);
  }

  &_theme_dark {
    background-color: transparent;
    color: var(--title-color);
    border-bottom: 1px solid #1e1e22;

    @include transition(0.3s, border);

    &:hover {
      border-color: var(--title-color);
    }

    &:focus {
      border-color: var(--action-color);
    }
  }

  &_size_medium {
    padding: 24px 20px;
    font-size: 18px;
    line-height: 1.4;
  }
}
</style>
