<script setup>
import { computed } from "vue";

const props = defineProps({
  value: {
    required: true,
    type: Number,
  },
  currentPage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["clickPaginationButton"]);

const buttonClick = () => {
  emit("clickPaginationButton", props.value);
};

const setActiveButton = computed(() => {
  return props.currentPage === props.value
    ? "pagination-button_is_current"
    : "pagination-button_is_default";
});
</script>

<template>
  <button
    class="pagination-button"
    :class="setActiveButton"
    @click="buttonClick"
  >
    {{ props.value }}
  </button>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.pagination-button {
  border-radius: 24px;
  border: none;
  padding: 6.5px 12.5px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @include transition(0.2s, color, background-color);

  &_is_current {
    background-color: var(--title-color);
    color: var(--main-color);
  }

  &_is_default {
    background-color: transparent;
    color: var(--title-color);
  }
}
</style>
