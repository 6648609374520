<template>
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_75_510)">
      <path
        d="M17.0057 21.6495C23.3051 12.5937 26.4548 8.06576 30.3618 7.25829C32.255 6.86702 34.2211 7.03291 36.022 7.73589C39.7385 9.18661 42.0849 14.1783 46.7778 24.1616C51.4707 34.1449 53.8171 39.1366 52.5629 42.9239C51.9551 44.7591 50.8284 46.3788 49.3192 47.587C46.2046 50.0802 40.7085 49.6164 29.7162 48.6889C18.724 47.7614 13.2278 47.2977 10.575 44.3178C9.28957 42.8739 8.4502 41.0883 8.15855 39.1771C7.55667 35.2332 10.7063 30.7053 17.0057 21.6495Z"
        fill="#A4FF51"
      />
    </g>
    <defs>
      <clipPath id="clip0_75_510">
        <rect width="63" height="63" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
