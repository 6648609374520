<template>
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31.5" cy="31.5" r="22.5" fill="#FF377F" />
  </svg>
</template>
