<script setup>
import emailjs from '@emailjs/browser';
import {ref} from "vue";
const formElement = ref(null)
const onSubmit = () => {
  emailjs.sendForm('service_fhxrllf', 'template_sjpi4ji', formElement.value, '2AydgvNLwq8VS4yQW')
      .then((result) => {
        console.log('SUCCESS!', result.text);
        alert('SUCCESS!')
      }, (error) => {
        console.log('FAILED...', error.text);
      });
}

</script>

<template>
  <form @submit.prevent="onSubmit" ref="formElement" class="form">
    <div class="form__inputs">
      <slot name="inputs"></slot>
    </div>
    <slot name="button"></slot>
  </form>
</template>

<style scoped lang="scss">
@import 'src/style/mixin';

.form {
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 92px;

    @include media-md {
      margin-bottom: 32px;
    }
  }
}
</style>
