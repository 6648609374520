<script setup>
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "medium",
  },
  color: {
    type: String,
    default: "gray",
  },
});

const setCardSize = computed(() => {
  switch (props.type) {
    case "small":
      return "card_size_small";
    case "medium":
      return "card_size_medium";
    default:
      return "";
  }
});

const setCardColor = computed(() => {
  switch (props.color) {
    case "gray":
      return "card_color_gray";
    case "green":
      return "card_color_green";
    default:
      return "";
  }
});
</script>

<template>
  <p class="card" :class="[setCardSize, setCardColor]">
    <slot />
  </p>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.card {
  border-radius: 24px;

  &_size_medium {
    padding: 32px;
  }

  &_size_small {
    padding: 18px 24px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.33px;
    text-align: center;

    @include media-md {
      padding: 24px 29px;
    }
  }

  &_color_gray {
    background-color: var(--card-color);
  }

  &_color_green {
    background-color: var(--brand-color);
  }
}
</style>
