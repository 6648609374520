<script setup>
import AppContainer from "@/components/common/AppContainer.vue";
import AppCard from "@/components/common/AppCard.vue";
import AppTitle from "@/components/common/AppTitle.vue";
import AppParagraph from "@/components/common/AppParagraph.vue";
import AppList from "@/components/common/AppList.vue";

const listOfWhoWeAre = [
  "A team of top specialists from around the world",
  "Every month the number of employees increases by 100+ people",
  "We create products and bring them to the top",
  "Industry looks up to us",
];

const listOfWhyUs = [
  "Quality, fast, reliable",
  "We never stop developing and are one step ahead of other product companies",
  "Multidisciplinary knowledge and skills based on the vast experience of our team of global experts",
];
</script>

<template>
  <div class="section-about-us" id="about-us">
    <AppContainer class="section-about-us__container">
      <div class="section-about-us__top">
        <AppCard class="section-about-us__card" color="green">
          <AppTitle class="section-about-us__title" color="black" size="big">
            Who we are?
          </AppTitle>

          <AppParagraph class="section-about-us__paragraph" size="small" color="green">
            We are the team that knows how to solve the client’s business
            problems with the assistance of marketing, advertising, design and
            PR tools.
          </AppParagraph>

          <AppParagraph class="section-about-us__paragraph" size="small" color="green">
            We create ideas that sell, make a profit and shape the long-term
            success of brands.
          </AppParagraph>
        </AppCard>

        <AppList
          class="section-about-us__list section-about-us__list-top"
          :list="listOfWhoWeAre"
          type="ol"
          color="white-opacity"
        />
      </div>
      <div class="section-about-us__bottom">
        <AppCard class="section-about-us__card">
          <AppTitle class="section-about-us__title" color="pink" size="big">
            Why us?
          </AppTitle>

          <AppParagraph class="section-about-us__paragraph" size="small" color="white">
            STMA Marketing Services designs and implements IT products of any
            complexity. We will help you create and develop selling websites and
            applications from scratch.
          </AppParagraph>

          <AppParagraph class="section-about-us__paragraph" size="small" color="white">
            Be one step ahead of your competitors with us.
          </AppParagraph>
        </AppCard>

        <AppList
          class="section-about-us__list"
          :list="listOfWhyUs"
          tag="ol"
          color="white-opacity"
        ></AppList>
      </div>
    </AppContainer>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/style/mixin';

.section-about-us {
  &__container {
    padding-bottom: 80px;
    
    @include media-md {
      padding-bottom: 120px;
    }

    @include media-xl {
      padding-bottom: 140px;
    }
  }

  &__top,
  &__bottom {
    @include media-md {
      display: flex;
      align-items: center;
      gap: 52px;
      margin-bottom: 32px;
    }

    @include media-xl {
      gap: 92px;
    }
  }

  &__card {
    max-width: 364px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 0 24px;

    @include media-md {
      margin: 0;
    }
  }

  &__title {
    margin: 0;
  }

  &__paragraph {
    margin: 0;
  }

  &__list {
    max-width: 417px;
    gap: 37px;

    &-top {
      margin: 0 0 63px;

      @include media-md {
        margin: 0;
      }
    }
  }
}
</style>
