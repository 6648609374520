<script setup></script>

<template>
  <div class="nav-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.nav-container {
  display: none;

  @include media-lg {
    max-width: 932px;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    border-right: 2px solid #1e1e22;
    pointer-events: none;
  }

  @include media-xl {
    max-width: 1024px;
  }

  @include media-xxl {
    max-width: 1300px;
  }
}
</style>
