<template>
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3449 10.3938C27.1746 7.47148 29.5895 6.01032 32.1968 6.23032C34.8041 6.45032 36.94 8.29546 41.2118 11.9857L48.2172 18.0376C52.4889 21.7278 54.6248 23.573 55.2213 26.1207C55.8178 28.6684 54.723 31.2699 52.5333 36.473L48.9425 45.0056C46.7529 50.2087 45.6581 52.8102 43.4194 54.1647C41.1807 55.5193 38.3682 55.282 32.7431 54.8074L23.5185 54.029C17.8935 53.5544 15.081 53.3171 13.1009 51.6065C11.1208 49.896 10.4774 47.1478 9.19059 41.6514L7.08028 32.6378C5.79345 27.1414 5.15003 24.3932 6.16498 21.9814C7.17993 19.5697 9.59479 18.1085 14.4245 15.1862L22.3449 10.3938Z"
      fill="#A4FF51"
    />
  </svg>
</template>
