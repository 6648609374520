<script setup>
const vacanciesLeft = [
  "Product Manager",
  "Project Manager",
  "PRC specialist",
  "SEO specialist",
  "Head of development",
  "Email Marketing Manager",
  "Brand Manager",
  "iOS-developer",
  "Middle Frontend",
  "Backend Dev",
  "QA Engineer",
];

const vacanciesRight = [
  "Android-developer",
  "GO-developer",
  "Affiliate manager",
  "Graphic Designer",
  "Call center operator",
  "Farmer",
  "Support Manager",
  "Head of Marketing",
  "UX / UI designer",
  "Billing manager",
  "Financial analyst",
];
</script>

<template>
  <ul class="vacancies-desktop__content">
    <li class="vacancies-desktop__content-item">
      <ul class="vacancies-desktop__list">
        <li
          class="vacancies-desktop__list-item"
          v-for="item in vacanciesLeft"
          :key="item"
        >
          {{ item }}
        </li>
      </ul>
    </li>
    <li class="vacancies-desktop__content-item">
      <ul class="vacancies-desktop__list">
        <li
          class="vacancies-desktop__list-item"
          v-for="item in vacanciesRight"
          :key="item"
        >
          {{ item }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.vacancies-desktop {
  &__content {
    display: flex;

    @include media-md {
      gap: 28px;
    }

    @include media-lg {
      gap: 31px;
    }

    @include media-xl {
      gap: 130px;
    }

    &-item {
      width: 100%;

      @include media-lg {
        max-width: 360px;
      }
    }
  }

  &__list {
    &-item {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.2;
      color: var(--title-color);
      border-bottom: 1px solid #1e1e22;

      &:first-child {
        padding-bottom: 24px;
      }

      &:not(:first-child) {
        padding: 24px 0;
      }
    }
  }
}
</style>
