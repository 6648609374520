<script setup>
import IconFile from '@/components/icons/IconFile.vue';
import { ref } from 'vue'

import { computed } from 'vue';

const props = defineProps({
    fileLoaderTheme: {
        type: String,
        default: 'gray'
    }
})

const textSpanOfInputFile = ref(null)

const setTheme = computed(() => {
    return 'file-loader_theme_' + props.fileLoaderTheme
})

const emit = defineEmits(['change'])

const onFileChange = (changeFileEvent) => {
    if (changeFileEvent.target.files[0]) textSpanOfInputFile.value.textContent = changeFileEvent.target.files[0].name
    else textSpanOfInputFile.value.textContent = 'Download your CV'
    
    emit('change', changeFileEvent.target.files[0])
}
</script>

<template>
  <label class="file-loader" :class="[setTheme]">
    <input type="file" @change="onFileChange" class="file-loader__input" accept=".doc,.docx,.pdf">
    <div class="file-loader__group">
        <IconFile class="file-loader__icon" />
        <span ref="textSpanOfInputFile">
            <slot></slot>
        </span>
    </div>
  </label>
</template>


<style scoped lang="scss">
@import 'src/style/mixin';

.file-loader {
    cursor: pointer;

    &_theme_gray {
        .file-loader__group {
            background-color: var(--card-color);
            color: var(--title-color);
            opacity: .8;
        }

        &:hover .file-loader__group {
            background-color: #2E2D2D;
        }

        .file-loader__input:focus+.file-loader__group {
            background-color: #4F4F4F;
        }
    }

    &__input {
        position: absolute;
        inset: 0;
        position: absolute;
        clip: rect(0 0 0 0);
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        gap: 10px;
        padding: 8px 10px;
        min-width: 195px;

        @include transition(.3s, background-color);

        @include media-lg {
            justify-content: flex-start;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        color: var(--brand-color);
    }
}
</style>