<script setup>
import AppPagination from "@/components/common/AppPagination.vue";
import { computed, onMounted, ref, watch } from "vue";
import AppArrowRight from "@/components/common/AppArrowRight.vue";

const listOfVacancies = [
  "Product Manager",
  "Project Manager",
  "PRC specialist",
  "SEO specialist",
  "Head of development",
  "Email Marketing Manager",
  "Brand Manager",
  "iOS-developer",
  "Middle Frontend",
  "Backend Dev",
  "QA Engineer",
  "Android-developer",
  "GO-developer",
  "Affiliate manager",
  "Graphic Designer",
  "Call center operator",
  "Farmer",
  "Support Manager",
  "Head of Marketing",
  "UX / UI designer",
  "Billing manager",
  "Financial analyst",
];

const currentPage = ref(1);
const elementsPerPage = 6;

const computedListOfVacancies = ref([]);

const setComputedListOfVacancies = () => {
  const offset = (currentPage.value - 1) * elementsPerPage;

  computedListOfVacancies.value = listOfVacancies.slice(
    offset,
    offset + elementsPerPage
  );
};

onMounted(() => {
  setComputedListOfVacancies();
});

watch(currentPage, () => {
  setComputedListOfVacancies();
});

const setPagination = computed(() => {
  return Math.ceil(listOfVacancies.length / elementsPerPage);
});

const setNextPage = () => {
  setPagination.value !== currentPage.value ? currentPage.value++ : null;
};

const setCurrentPageFromButtons = (pageNumber) => {
  currentPage.value = pageNumber;
};
</script>

<template>
  <ul class="vacancies-list">
    <li
      class="vacancies-list__vacancy"
      v-for="item in computedListOfVacancies"
      :key="item"
    >
      {{ item }}
    </li>
  </ul>

  <div class="vacancies-list__bottom">
    <AppPagination
      :length-of-array="setPagination"
      :current-page="currentPage"
      @click-pagination-button="setCurrentPageFromButtons"
    />

    <AppArrowRight @click="setNextPage" />
  </div>
</template>

<style lang="scss" scoped>
.vacancies-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  min-height: 396px;

  &__vacancy {
    color: var(--title-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.3px;
    padding-bottom: 24px;
    border-bottom: 1px solid #1e1e22;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
</style>
