<script setup>
import AppContainer from "@/components/common/AppContainer.vue";
import AppTitle from "@/components/common/AppTitle.vue";
import AppCard from "@/components/common/AppCard.vue";

const listOfExpertise = [
  "Web Development",
  "Advertising",
  "Reputation Management",
  "SMM",
  "Consulting",
  "Search Engine Optimisation (SEO)",
  "Brand Strategy",
  "Video Production",
  "Convertion Optimization",
];

const setCurrentClass = (name, number) => {
  return `section-our-expertise__item-${name
    .slice(0, 3)
    .toLowerCase()}${number}`;
};
</script>

<template>
  <section class="section-our-expertise" id="our-expertise">
    <AppContainer class="section-our-expertise__container">
      <AppTitle class="section-our-expertise__title" size="small"
        >Our expertise</AppTitle
      >

      <ul class="section-our-expertise__list">
        <li
          class="section-our-expertise__item"
          v-for="(item, index) in listOfExpertise"
          :key="item"
          :class="setCurrentClass(item, index)"
        >
          <AppCard type="small" class="section-our-expertise__card">{{
            item
          }}</AppCard>
        </li>
      </ul>
    </AppContainer>
  </section>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.section-our-expertise {
  &__container {
    padding-bottom: 80px;

    @include media-md {
      padding-bottom: 30px;
    }

    @include media-lg {
      padding-bottom: 120px;
    }

    @include media-xl {
      padding-bottom: 140px;
    }
  }

  &__title {
    margin: 0 0 32px;

    @include media-md {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, minmax(64px, auto));
    gap: 12px;
    grid-template-areas:
      "web0 web0 web0"
      "smm3 con4 con4"
      "rep2 rep2 rep2"
      "bra6 bra6 bra6"
      "sea5 sea5 sea5"
      "vid7 vid7 vid7"
      "con8 con8 con8"
      "adv1 adv1 adv1";

    @include media-md {
      gap: 16px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 74px);
      grid-template-areas:
        "web0 web0 web0 web0 web0 web0 adv1 adv1 adv1 adv1 adv1 adv1"
        "smm3 smm3 con4 con4 con4 rep2 rep2 rep2 rep2 rep2 rep2 rep2"
        "bra6 bra6 bra6 bra6 sea5 sea5 sea5 sea5 sea5 sea5 sea5 sea5"
        "vid7 vid7 vid7 vid7 vid7 con8 con8 con8 con8 con8 con8 con8";
    }

    @include media-lg {
      display: flex;
      flex-wrap: wrap;
      max-width: 743px;
    }

    @include media-xl {
      max-width: 850px;
    }
  }

  &__item {
    @include media-lg {
      flex-grow: 1;
    }

    &-web0 {
      grid-area: web0;
    }

    &-adv1 {
      grid-area: adv1;
    }

    &-rep2 {
      grid-area: rep2;
    }

    &-smm3 {
      grid-area: smm3;
    }

    &-con4 {
      grid-area: con4;
    }

    &-sea5 {
      grid-area: sea5;
    }

    &-bra6 {
      grid-area: bra6;
    }

    &-vid7 {
      grid-area: vid7;
    }

    &-con8 {
      grid-area: con8;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    color: var(--title-color);
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.33px;
    cursor: default;

    @include transition(0.3s, background-color, color);

    &:hover {
      color: var(--main-color);
      background-color: var(--brand-color);
    }
  }
}
</style>
