<script setup>
import { computed } from "vue";

const props = defineProps({
  tag: {
    type: String,
    default: "h2",
  },
  size: {
    type: String,
    default: "medium",
  },
  color: {
    type: String,
    default: "white",
  },
});

const setTitleSize = computed(() => {
  switch (props.size) {
    case "big":
      return "title_size_big";
    case "medium":
      return "title_size_medium";
    case "small":
      return "title_size_small";
    default:
      return "";
  }
});

const setTitleColor = computed(() => {
  switch (props.color) {
    case "white":
      return "title_color_white";
    case "pink":
      return "title_color_pink";
    case "black":
      return "title_color_black";
    default:
      return "";
  }
});
</script>

<template>
  <component :is="tag" class="title" :class="[setTitleSize, setTitleColor]">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.title {
  @include media-md {
    text-transform: capitalize;
  }

  &_size_small {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.51px;

    @include media-md {
      font-size: 48px;
    }
  }

  &_size_medium {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.51px;

    @include media-md {
      font-size: 48px;
      line-height: 1;
    }
  }

  &_size_big {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.51px;

    @include media-md {
      font-size: 48px;
      font-weight: 800;
      line-height: 1.2;
      letter-spacing: -1.689px;
    }
  }

  &_color_white {
    color: var(--title-color);
  }

  &_color_black {
    color: var(--main-color);
  }

  &_color_pink {
    color: var(--action-color);
  }
}
</style>
