<template>
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_75_514)">
      <path
        d="M7.64001 22.3778C9.72863 13.184 10.7729 8.58707 14.2779 6.37976C17.7829 4.17245 22.3799 5.21676 31.5737 7.30537L34.5137 7.97326C43.7075 10.0619 48.3044 11.1062 50.5117 14.6112C52.7191 18.1162 51.6747 22.7131 49.5861 31.9069L48.9182 34.8469C46.8296 44.0408 45.7853 48.6377 42.2803 50.845C38.7753 53.0523 34.1784 52.008 24.9846 49.9194L22.0446 49.2515C12.8507 47.1629 8.25382 46.1186 6.04651 42.6136C3.8392 39.1086 4.88351 34.5116 6.97212 25.3178L7.64001 22.3778Z"
        fill="#FF377F"
      />
    </g>
    <defs>
      <clipPath id="clip0_75_514">
        <rect width="63" height="63" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
