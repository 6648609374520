<script setup>
import AppPaginationButton from "@/components/common/AppPaginationButton.vue";

const props = defineProps({
  lengthOfArray: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["clickPaginationButton"]);

const buttonClick = (value) => {
  emit("clickPaginationButton", value);
};
</script>

<template>
  <ul class="pagination">
    <li
      class="pagination__item"
      v-for="item in props.lengthOfArray"
      :key="item"
    >
      <AppPaginationButton
        :value="item"
        :current-page="currentPage"
        @click-pagination-button="buttonClick"
      />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
