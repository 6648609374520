<script setup>
import {computed} from "vue";

const emit = defineEmits({
  'update:modelValue': Boolean
})

const props = defineProps({
  'modelValue': Boolean
})

const onClick = () => {
  emit('update:modelValue', !props.modelValue)
}

const isActiveClass = computed(() => props.modelValue ? 'burger-button_is_active' : '')
</script>
<template>
  <button @click="onClick" class="burger-button" :class="isActiveClass">
    <span class="burger-button__line"></span>
    <span class="burger-button__line"></span>
    <span class="burger-button__line"></span>
  </button>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.burger-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: var(--title-color);
  border: none;
  border-radius: 28px;
  backdrop-filter: blur(42px);
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 0;

  &__line {
    position: absolute;
    width: 15.5px;
    height: 1.5px;
    background-color: #010101;
    border-radius: 28px;

    @include transition(.15s, opacity, transform);
    transform-origin: center;

    &:nth-child(1) {
      transform: translateY(-5px);
    }

    &:nth-child(3) {
      transform: translateY(5px);
    }
  }

  &_is_active {
    .burger-button__line {
      &:nth-child(1) {
        transform: translateY(0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }

}
</style>
