<script setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "medium",
  },
  color: {
    type: String,
    default: "gray",
  },
});

const setParagraphSize = computed(() => {
  switch (props.size) {
    case "small":
      return "paragraph_size_small";
    case "medium":
      return "paragraph_size_medium";
    default:
      return "";
  }
});

const setParagraphColor = computed(() => {
  switch (props.color) {
    case "gray":
      return "paragraph_color_gray";
    case "lightgray":
      return "paragraph_color_lightgray";
    case "white":
      return "paragraph_color_white";
    case "green":
      return "paragraph_color_green";
    case "lightgreen":
      return "paragraph_color_lightgreen";
    default:
      return "";
  }
});
</script>

<template>
  <p class="paragraph" :class="[setParagraphSize, setParagraphColor]">
    <slot />
  </p>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.paragraph {
  &_size_small {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.35;

    @include media-md {
      line-height: 1.6;
      font-size: 18px;
    }
  }

  &_size_medium {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
  }

  &_color_gray {
    color: var(--paragraph-color);
  }

  &_color_lightgray {
    color: var(--paragraph-inactive-color);
  }

  &_color_green {
    color: var(--paragraph-secondary-color);
    opacity: 0.8;
  }

  &_color_lightgreen{
    color: var(--brand-color);
  }

  &_color_white {
    color: var(--title-color);
    opacity: 0.8;
  }
}
</style>
