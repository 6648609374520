<script setup>
import AppContainer from "@/components/common/AppContainer.vue";
import AppTitle from "@/components/common/AppTitle.vue";
import AppParagraph from "@/components/common/AppParagraph.vue";
import AppBurgerButton from "@/components/AppBurgerButton.vue";
import {defineAsyncComponent, onMounted, ref} from "vue";

const AppMobileMenu = defineAsyncComponent({
  loader: () => import("@/components/common/AppMobileMenu.vue")
})

const isActiveMobileMenu = ref(false)

const onClickInMobileMenu = () => isActiveMobileMenu.value = false

const media = matchMedia('(min-width: 1024px)')

onMounted(() => {
  media.addEventListener('change', (event) => {
    const isDesktop = event.matches
    if (isActiveMobileMenu.value && isDesktop) {
      isActiveMobileMenu.value = false
    }
  })
})
</script>

<template>
  <section class="section-hero">
    <AppBurgerButton v-model="isActiveMobileMenu" class="section-hero__burger-button"/>

    <video
        preload="auto"
        autoplay="autoplay"
        playsinline
        muted
        loop
        class="section-hero__video"
    >
      <source src="/video/smoke.webm" type="video/webm"/>
      <source src="/video/smoke.mp4" type="video/mp4"/>
    </video>
    <AppContainer class="section-hero__container">
      <p class="section-hero__content">
        <span class="section-hero__company-name">STMA</span>

        <AppTitle class="section-hero__title" tag="h1"
        >Marketing Services via Social Media
        </AppTitle
        >

        <AppParagraph class="section-hero__paragraph">
          One of the most creative and innovative teams who knows everything
          about marketing and is able to make any product real.
        </AppParagraph>
      </p>
    </AppContainer>
  </section>

  <AppMobileMenu @onClickLink="onClickInMobileMenu" v-if="isActiveMobileMenu"/>
</template>

<style lang="scss" scoped>
@import "src/style/mixin";

.section-hero {
  position: relative;

  &__burger-button {
    position: absolute;
    top: 14px;
    right: 12px;

    @include media-md {
      top: 28px;
      right: 24px;
    }

    @include media-lg {
      display: none;
    }
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 332px;
    inset: 0;
    z-index: -1;
    object-fit: cover;

    @include media-md {
      height: 561px;
    }

    @include media-xl {
      height: 730px;
    }

    @include media-xxl {
      height: 100%;
    }
  }

  &__container {
    display: flex;
    padding-top: 286px;
    padding-bottom: 57px;

    @include media-md {
      padding-top: 108px;
      padding-bottom: 198px;
    }

    @include media-xl {
      padding-top: 144px;
      padding-bottom: 231px;
    }

    @include media-xxl {
      padding-top: 225px;
      padding-bottom: 329px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @include media-md {
      align-items: flex-start;
    }
  }

  &__company-name {
    color: var(--brand-color);
    text-shadow: 0 25.57px 400.3px rgba(0, 0, 0, 0.2);
    font-weight: 700;
    line-height: 1;
    letter-spacing: -5.245px;
    font-size: 90px;
    margin-bottom: 12px;

    @include media-md {
      font-size: 160px;
      margin-bottom: 8px;
    }
  }

  &__title {
    max-width: 441px;
    margin: 0 0 12px;
    text-align: center;

    @include media-md {
      text-align: left;
      margin: 0 0 24px;
    }
  }

  &__paragraph {
    max-width: 435px;
    margin: 0;
    text-align: center;

    @include media-md {
      text-align: left;
    }
  }
}
</style>
